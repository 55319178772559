import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { envs } from '../../redux/api/windowServerData'
import { MuiBox } from 'components/storybook'

export function PolyCyPage() {
    const [policyData, setPolicyData] = useState()

    async function getPolicyData() {
        const BASE_URL = envs.REACT_APP_GERMINI_API_URL
        const response = await axios.get(
            `${BASE_URL}/ProgramRules/PrivacyPolicies`,
        )

        setPolicyData(response.data)
    }

    useEffect(() => {
        getPolicyData()
    }, [])

    return (
        <MuiBox pl={2} pr={2} pb={2}>
            <span
                dangerouslySetInnerHTML={{
                    __html: policyData?.privacyPolicies,
                }}
            />
        </MuiBox>
    )
}
