import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { HideLoader } from 'redux/actions'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'
import Section from '../../../../components/Section/Section'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import * as Styled from '../../../DigitalWallet/styles'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import {
    AwardConsumer,
    Button,
    CalendarConsumer,
    CashbackConsumer,
    ChangeConsumer,
    CreditConsumer,
    GiftVoucherConsumer,
    MuiBox,
    MuiDefaultModal,
    MuiDivider,
    MuiStack,
    MuiTypography,
    OperationsConsumer,
    PartnershipsConsumer,
    PurchaseValueConsumer,
    RefundIcon,
    WithdrawConsumer,
} from '../../../../components/storybook'
import { numberFormatText } from '../../../../utils/utils'
import { format } from 'date-fns'
import { ViewPurchase } from './programType'
import moment from 'moment'

export function ModalDetails({ closeByClick, data, open }) {
    const { t } = useTranslation()

    const [programRules, setProgramRules] = useState([])
    const dispatch = useDispatch()

    function formatDate(date) {
        if (typeof date === 'string') {
            return format(new Date(date), 'dd/MM/yyyy') ?? '-'
        } else {
            return '-'
        }
    }

    const typeGroup = {
        Category: 'Categoria',
        Brand: 'Marca',
        Family: 'Família',
        Manufacturer: 'Fabricante',
        Product: 'Produto',
    }

    const typeOperation = {
        TRANSFER: 'Transferência',
        CREDIT: 'Crédito',
        DEBIT: 'Débito',
        BLOCKED: 'Bloqueado',
        EXPIRATION: 'Expirado',
        UNLOCKED: 'Rejeitado',
        RELEASED: 'Liberado',
    }

    const items = [
        {
            label: 'Origem',
            icon: <CashbackConsumer size={30} color="#B8B8B8" />,
            value: data?.negotiation
                ? data?.negotiation?.negotiationCampaign?.value
                : data?.partnerName || '-- --',

            type: ['CREDIT', 'REFUND'],
        },
        {
            label: 'Destinatário',
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value:
                data?.entryType?.key === '2' && data?.annonymousSent ? (
                    <Styled.MyTooltip
                        arrow
                        id="tooltip-top"
                        title={t('ANONYMOUS_GIFT_SENT')}
                        placement="top-start"
                    >
                        <i
                            style={{
                                fontSize: 20,
                                position: 'relative',
                                left: '14px',
                                color: germiniDefaultColors[0],
                                marginRight: '20px',
                            }}
                            className="icon-anonimo"
                        />
                    </Styled.MyTooltip>
                ) : !isEmpty(data) &&
                  data?.partnerName !== null &&
                  data?.partnerName !== '' ? (
                    data?.partnerName
                ) : data?.annonymousSent ? (
                    t('ANONYMOUS_SENT')
                ) : (
                    (data?.originPlatform?.key === 1 && (
                        <a className="originPlatform">- PDV Virtual</a>
                    )) ||
                    '-- --'
                ),
            type: ['STORE', 'DEBIT'],
        },
        {
            label: 'Evento',
            icon: <OperationsConsumer size={30} color="#B8B8B8" />,
            value: t(data?.sourceType?.value) ?? '-- --',
            type: ['CASHBACK', 'CREDIT', 'DEBIT', 'REFUND'],
        },
        {
            label: 'Operação',
            icon: <RefundIcon size={30} color="#B8B8B8" />,
            value: typeOperation[data?.transactionType?.value] ?? '-- --',
            type: [
                'REFUND',
                'BLOCKED',
                'CASHBACK',
                'CREDIT',
                'DEBIT',
                'EXPIRED',
                'RELEASED',
            ],
        },
        !data?.negotiation &&
            data?.sourceType?.value !== 'Premiação' &&
            data?.sourceType?.value !== 'Ant. Premiação' &&
            data?.sourceType?.value !== 'CONVERSION' && {
                label: 'Valor da compra',
                icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
                value:
                    !isEmpty(data) && data !== null && data !== ''
                        ? data?.value.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          })
                        : '-- --',
                type: ['REFUND', 'CREDIT'],
                negotiation: false,
            },
        !data?.negotiation &&
            data?.sourceType?.value !== 'DIGITAL_CHANGE' &&
            data?.sourceType?.value !== 'CONVERSION' && {
                label: 'Data do evento',
                icon: <CalendarConsumer size={30} color="#B8B8B8" />,
                value:
                    !isEmpty(data) &&
                    data?.processingDate !== null &&
                    data?.processingDate !== ''
                        ? moment(data?.processingDate).format('L') +
                          ' - ' +
                          moment(data?.processingDate).format('HH:mm')
                        : '-- --',
                type: [
                    'CASHBACK',
                    'BLOCKED',
                    'DEBIT',
                    'CREDIT',
                    'EXPIRED',
                    'RELEASED',
                ],
            },
        data?.sourceType?.value === 'CONVERSION' && {
            label: 'Data da operação',
            icon: <CalendarConsumer size={30} color="#B8B8B8" />,
            value:
                !isEmpty(data) &&
                data?.processingDate !== null &&
                data?.processingDate !== '' &&
                moment(data?.processingDate).format('L'),
            type: [
                'CASHBACK',
                'BLOCKED',
                'DEBIT',
                'CREDIT',
                'EXPIRED',
                'RELEASED',
            ],
        },
        data?.sourceType?.value === 'DIGITAL_CHANGE' && {
            label: 'Data da compra',
            icon: <CalendarConsumer size={30} color="#B8B8B8" />,
            value: formatDate(data?.processingDate) ?? '-- --',

            type: [
                'CASHBACK',
                'BLOCKED',
                'DEBIT',
                'CREDIT',
                'EXPIRED',
                'RELEASED',
            ],
        },
        data?.sourceType?.value === 'DIGITAL_CHANGE' && {
            label: 'Data da utilização',
            icon: <CalendarConsumer size={30} color="#B8B8B8" />,
            value: formatDate(data?.useDate) ?? '-- --',
            type: [
                'CASHBACK',
                'BLOCKED',
                'DEBIT',
                'CREDIT',
                'EXPIRED',
                'RELEASED',
            ],
        },
        !data?.negotiation &&
            data?.sourceType?.value !== 'DIGITAL_CHANGE' &&
            data?.sourceType?.value !== 'CASHBACK' &&
            data?.sourceType?.value !== 'CONVERSION' && {
                label: 'Data de carência',
                icon: <CalendarConsumer size={30} color="#B8B8B8" />,
                value: formatDate(data?.expirationDate) ?? '-- --',
                type: ['BLOCKED', 'EXPIRED', 'RELEASED', 'PROCESSED'],
            },
        {
            label: 'Data de início',
            icon: <CalendarConsumer size={30} color="#B8B8B8" />,
            value:
                !isEmpty(data) &&
                data?.processingDate !== null &&
                data?.processingDate !== ''
                    ? moment(data?.processingDate).format('L') +
                      ' - ' +
                      moment(data?.processingDate).format('HH:mm')
                    : '-- --',
            type: ['CREDIT', 'REFUND'],
            negotiation: true,
        },
        data?.sourceType?.value !== 'Premiação' &&
            data?.sourceType?.value !== 'Ant. Premiação' &&
            data?.sourceType?.value !== 'DIGITAL_CHANGE' &&
            data?.sourceType?.value !== 'CONVERSION' && {
                label: 'Data de expiração',
                icon: <CalendarConsumer size={30} color="#B8B8B8" />,
                value: formatDate(data?.expirationDate) ?? '-- --',
                type: ['CREDIT'],
            },
        {
            label: 'Liberado para transferência',
            icon: <CalendarConsumer size={30} color="#B8B8B8" />,
            value: formatDate(data?.negotiation?.transferValidAfter) ?? '-- --',
            type: ['CREDIT', 'negotiation'],
            negotiation: true,
        },

        {
            label: 'Valor total do benefício',
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value:
                !isEmpty(data) && data !== null && data !== ''
                    ? data?.value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                      })
                    : '-- --',
            type: ['CREDIT'],
            negotiation: true,
        },

        {
            label: 'Valor da transferência',
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value: data?.liquidValue
                ? data?.liquidValue?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                  }) || '-'
                : ' - ',
            type: ['BLOCKED', 'EXPIRED', 'RELEASED'],
        },

        data?.sourceType?.value !== 'TRANSFER' && {
            label: `${t('ANTICIPATION_RATE')} (${
                data?.anticipationFees[0]?.anticipationFeePercentage ?? '-- --'
            }%)`,
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value: !isEmpty(data)
                ? numberFormatText(
                      data?.anticipationFees[0]?.anticipationFee ?? 0,
                      programRules.currencySymbol
                          ? programRules.currencySymbol + ' '
                          : ' ',
                      2,
                  )
                : 0,
            type: [
                'REFUND',
                'BLOCKED',
                'EXPIRED',
                'RELEASED',
                'Premiação',
                'Ant. Premiação',
            ],
        },
        {
            label:
                data?.transferFeeType?.key === '2'
                    ? `${t('TRANSFER_RATE')} (${
                          data?.transferFeePercentage
                      }%)` ?? '-- --'
                    : `${t('TRANSFER_RATE')} (${
                          data?.transferFee?.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          }) ?? '-- --'
                      } ) ` || '-- --',
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value:
                data?.transferFee?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }) ?? '-- --',
            type: ['BLOCKED', 'EXPIRED', 'RELEASED'],
        },
        {
            label: 'Parceiro',
            icon: <PartnershipsConsumer size={30} color="#B8B8B8" />,
            value: data?.partnerName ?? '-- --',
            type: ['CREDIT'],
            negotiation: true,
        },
        {
            label: 'Cód (SKU)',
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value: data?.negotiation?.product?.key ?? '-- --',
            type: ['CREDIT'],
            negotiation: true,
        },
        {
            label: typeGroup[data?.negotiation?.typeOfGrouper?.value],
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value: data?.negotiation?.product?.value ?? '-- --',
            type: ['CREDIT'],
            negotiation: true,
        },
        {
            label: 'Qtde de itens negociada',
            icon: <PurchaseValueConsumer size={30} color="#B8B8B8" />,
            value: data?.quantityUsedNegotiation ?? '-- --',
            type: ['CREDIT'],
            negotiation: true,
        },
    ]

    const prefix = [
        {
            key: 0,
            value: 'Valor da operação',
            icon: <CreditConsumer size={40} color="#DE2E2E" />,
        },
        {
            key: 1,
            value: 'Valor da operação',
            icon: (
                <CashbackConsumer
                    size={40}
                    color={
                        data?.transactionType?.key === '2' ||
                        data?.status?.key === '2'
                            ? '#F44336'
                            : '#5CB860'
                    }
                />
            ),
        },
        {
            key: 2,
            value: 'Valor da operação',
            icon: (
                <GiftVoucherConsumer
                    size={40}
                    color={
                        data?.transactionType?.key === '2' ||
                        data?.status?.key === '2'
                            ? '#F44336'
                            : '#5CB860'
                    }
                />
            ),
        },
        {
            key: 3,
            value: 'Valor da operação',
            icon: <ChangeConsumer size={40} color={'#7AD474'} />,
        },
        {
            key: 4,
            value: 'Valor da operação',
            icon: (
                <CreditConsumer
                    size={40}
                    color={
                        data?.transactionType?.key === '2' ||
                        data?.status?.key === '2'
                            ? '#F44336'
                            : '#5CB860'
                    }
                />
            ),
        },
        {
            key: 5,
            value: 'Valor da operação',
            icon: <WithdrawConsumer size={40} color="#9C571A" />,
        },
        { key: 6, value: 'Valor da operação', icon: 'icon-carteira_estorno' },
        {
            key: 7,
            value: 'Valor da operação',
            icon: (
                <WithdrawConsumer
                    size={40}
                    color={
                        data?.transactionType?.key === '7'
                            ? '#F44336'
                            : data?.transactionType?.key === '5'
                            ? '#FF9800'
                            : data?.transactionType?.key === '3'
                            ? '#9C571A'
                            : '#7F22DB'
                    }
                />
            ),
        },
        ,
    ]

    useEffect(() => {
        async function pageLoad() {
            try {
                const response = await axios.get(`/ProgramRules/General`)
                setProgramRules(response.data)
            } catch (error) {
            } finally {
                dispatch(HideLoader())
            }
        }
        pageLoad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 580,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    }

    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={open}
            onClose={closeByClick}
        >
            <MuiBox sx={{ ...style, maxHeight: '90vh', overflowY: 'auto' }}>
                <Section
                    title="Dados da movimentação"
                    children={
                        <Button
                            hoverColor="#F05B4F"
                            hoverTextColor="#F05B4F"
                            text="Fechar"
                            textColor="#FFFFFF"
                            typeVariation="CustomTextButton"
                            onClick={closeByClick}
                            icon
                            colorIcon="#FFFFFF"
                            sx={{
                                borderRadius: '5px',
                                backgroundColor: '#F05B4F',
                                height: '35px',
                                fontWeight: 400,
                                border: 'none',
                                textTransform: 'inherit',
                                color: '#FFFFFF',
                                ':hover': {
                                    backgroundColor: '#F05B4F',
                                    color: '#FFFFFF',
                                    border: 'none',
                                },
                            }}
                        />
                    }
                />
                <MuiStack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    mb={3}
                >
                    {data?.originPlatform?.key === '3' ? (
                        <AwardConsumer size={40} color={'#7AD474'} />
                    ) : data?.status?.key === '2' &&
                      data?.sourceType?.key !== '7' &&
                      data?.sourceType?.key !== '1' ? (
                        <WithdrawConsumer size={40} color="#9C571A" />
                    ) : data?.sourceType?.key.length > 7 &&
                      data?.sourceType?.key !== '7' ? (
                        <CreditConsumer size={40} color="#DE2E2E" />
                    ) : (
                        <i
                            style={
                                data?.sourceType?.key === '7'
                                    ? {
                                          color: `${
                                              data?.transactionType.key === '7'
                                                  ? '#F44336'
                                                  : data?.transactionType
                                                        .key === '5'
                                                  ? '#FF9800'
                                                  : data?.transactionType
                                                        .key === '3'
                                                  ? '#9C571A'
                                                  : '#7F22DB'
                                          }`,
                                      }
                                    : { color: '#7AD474' }
                            }
                            className={prefix[data?.sourceType?.key]?.icon}
                        />
                    )}
                    {data?.sourceType?.key === '10' && (
                        <CashbackConsumer
                            size={40}
                            color={
                                data?.status?.key === '0'
                                    ? '#5CB860'
                                    : '#F44336'
                            }
                        />
                    )}
                    {prefix[data?.sourceType?.key]?.icon}
                    <MuiTypography fontSize="15px" fontWeight={600}>
                        {data?.originPlatform?.key === '3' ||
                        data?.sourceType?.key === '10'
                            ? 'Valor da operação'
                            : prefix[data?.sourceType?.key]?.value ||
                              '' + ' ' + t(data?.sourceType?.value) + ':'}
                    </MuiTypography>
                    <MuiTypography
                        color={
                            data?.sourceType?.key
                                ? {
                                      color: `${
                                          data.sourceType.key === '7'
                                              ? data.transactionType.key === '7'
                                                  ? '#F44336'
                                                  : data.transactionType.key ===
                                                    '5'
                                                  ? '#FF9800'
                                                  : data.transactionType.key ===
                                                    '3'
                                                  ? '#9C571A'
                                                  : '#7F22DB'
                                              : data.transactionType.key === '2'
                                              ? '#F44336'
                                              : '#5CB860' ||
                                                data?.sourceType?.key === '1'
                                              ? data?.transactionType?.key ===
                                                '3'
                                                  ? '#F44336'
                                                  : '#5CB860'
                                              : '#5CB860 '
                                      }`,
                                  }
                                : { color: '#7AD474' }
                        }
                        fontSize="15px"
                        fontWeight={600}
                    >
                        {!isEmpty(data)
                            ? numberFormatText(
                                  data?.value,
                                  programRules?.currencySymbol
                                      ? programRules?.currencySymbol + ' '
                                      : ' ',
                                  2,
                              )
                            : ' - '}
                    </MuiTypography>
                </MuiStack>

                <MuiBox mb={3}>
                    {(data?.sourceType?.key === '7' &&
                        data?.transactionType?.key === '6') ||
                    data?.transactionType?.key === '3' ? (
                        <S.rejected>
                            <div>
                                {data?.status?.key === '2' &&
                                data?.transactionType?.key === '3' ? (
                                    <>
                                        <i className="icon-alerta" />
                                        <p>EXPIRADO</p>
                                    </>
                                ) : (
                                    <>
                                        <i className="icon-alerta" />{' '}
                                        <p>REJEITADO</p>
                                    </>
                                )}
                            </div>
                            <p>
                                {data?.transactionType?.key === '6'
                                    ? data?.transferReason
                                    : 'Prazo de confirmação expirado, o valor retornou para sua carteira'}
                            </p>
                        </S.rejected>
                    ) : (
                        ''
                    )}
                </MuiBox>

                {items?.map((item, index) => {
                    const shouldRender =
                        item?.type?.includes(data?.transactionType?.value) ||
                        item?.type?.includes(data?.status?.value) ||
                        item?.type?.includes(data?.sourceType?.value)
                    if (!shouldRender) {
                        return null
                    }

                    return (
                        <>
                            <MuiStack
                                key={index}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                spacing={1}
                                width="100%"
                                mb={2}
                                sx={{
                                    display:
                                        item?.negotiation &&
                                        !data?.negotiation &&
                                        'none',
                                }}
                            >
                                {item?.icon}
                                <MuiTypography fontSize="12px" color="#333333">
                                    {item?.label}
                                </MuiTypography>
                                <MuiDivider
                                    sx={{ flexGrow: 1, borderStyle: 'dashed' }}
                                />
                                <MuiTypography
                                    color={
                                        GetThemeColorFromStorage()?.footer
                                            ?.backgroundColor
                                    }
                                    fontWeight={500}
                                    fontSize="12px"
                                >
                                    {item?.value}
                                </MuiTypography>
                            </MuiStack>
                        </>
                    )
                })}
                {(data?.transactionType?.key === '2' &&
                    data?.invoiceId &&
                    data?.invoiceId) ||
                (data?.sourceType?.key === '1' &&
                    data?.invoiceId &&
                    data?.invoiceId) ? (
                    <ViewPurchase data={data} />
                ) : null}
            </MuiBox>
        </MuiDefaultModal>
    )
}
