export const initialFormValues = {
    cpf: '',
    name: '',
    dateOfBirth: '',
    gender: '',
    email: '',
    phoneNumber2: '',
    emailPre: '',
    phoneNumber2Pre: '',
	InvalidEmail: false,
	IsError: false,
	isPreRegistration: false,
	isStateDisabled: false,
	disabledDateOfBirth: false,
    stateRegistrationWaiver: false,
	listState: '',
	RegistrationState: '',
    stateRegistration: '',
    password: '',
    confirmPassword: '',
    address: {
        zipCode: '',
        location: '',
        number: '',
        aditionalInfo: '',
        district: '',
        cityId: '',
        stateId: '',
        countryId: '',
    },
    representative: {
        nameRP: '',
        cpfRP: '',
        emailRP: '',
        phoneNumberRP: '',
    },
    nameRP: '',
    cpfRP: '',
    emailRP: '',
    phoneNumberRP: '',
    selectAll: false,
    useTerms: false,
    useRegulation: false,
    newsletter: false,
    smsReceiveNumber: 0,
	isMEI: false,
}
