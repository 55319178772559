import React, { Fragment } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import Skeleton from 'react-loading-skeleton'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section/Section'

import styles from './styles.module.scss'
import compose from 'utils/compose'

import {
	GetActiveTierData,
	GetCurrentConsumer,
	ShowLoader,
	HideLoader,
} from 'redux/actions'
import { getProgramTitle } from '../../utils/utils'

class Category extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			currentTier: {},
			nextTier: {},
			validFrom: '',
			validUntil: '',
			isActive: true,
			tiers: [],
			loading: null,
		}
		this.orderTiersData = this.orderTiersData.bind(this)
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		if (_.isEmpty(this.props.userData)) {
			this.props.GetCurrentConsumer().then(() => {
				this.props.GetActiveTierData()
			})
		} else {
			this.props.GetActiveTierData()
		}
		//
		// this.props.generalRulesData.allowDigitalWallet
		if (!this.props.generalRulesData.allowProgramLevels) {
			this.props.history.push(`/home`)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userData !== this.props.userData) {
			this.props.GetActiveTierData()
		}

		if (prevProps.activeTierData !== this.props.activeTierData) {
			this.setState({ ...this.props.activeTierData })
			this.props.HideLoader()
		}

		if (prevState.tiers !== this.state.tiers) {
			this.setCurrentNextTier(
				this.props.userData.points,
				this.state.tiers,
			)
		}

		if (
			prevProps.activeTierDataFailed !== this.props.activeTierDataFailed
		) {
			this.props.HideLoader()
			//console.log('FAILED ON LOADING TIERS DATA')
		}

		if (prevState.currentTier !== this.state.currentTier) {
			// console.log('Current Tier: ', this.state.currentTier);
		}

		if (prevState.nextTier !== this.state.nextTier) {
			// console.log('nextTier: ', this.state.nextTier);
		}
	}

	orderTiersData = (tiersList) => {
		let tiers = _.filter(tiersList, 'isActive')
		tiers = _.orderBy(tiers, ['scoreStart'], ['cresc'])
		this.setState({ tiersOrdered: tiers })
		return tiers
	}

	setCurrentNextTier = (points, tiers) => {
		_.forEach(tiers, (t, i) => {
			if (points >= t.scoreStart) {
				this.setState({ currentTier: t })
				if (!t.isFinalTier) {
					this.setState({ nextTier: t })
				}
			} else {
				this.setState({ nextTier: t })
				return false
			}
		})
		this.setState({ loading: true })
	}

	getExpirePointsTime = (generalRules) => {
		if (generalRules.expirePeriodType === 1) {
			return 'SELECT_GENERAL_RULES_MONTHS'
		} else if (generalRules.expirePeriodType === 12) {
			return 'SELECT_GENERAL_RULES_YEARS'
		}
		return ''
	}

	render() {
		const { t, classes, userData, generalRulesData } = this.props
		const { tiers, nextTier, currentTier, validUntil, loading } = this.state

		var shimmer = (
			<div>
				<div className={styles.boxCategory}>
					<div
						style={{ marginRight: '20px', display: 'inline-grid' }}
					>
						<span>
							<Skeleton height={30} width={200} />{' '}
						</span>
						<span>
							<Skeleton height={30} width={200} />{' '}
						</span>
					</div>
					<Skeleton height={70} width={50} />
				</div>
				<div style={{ marginTop: '25px', marginBottom: '20px' }}>
					<p>
						<Skeleton height={25} />
					</p>
				</div>
				<div
					style={{
						marginTop: '25px',
						marginBottom: '20px',
						display: 'flex',
					}}
				>
					<div style={{ width: '15%' }}>
						<Skeleton height={98} width={135} />
					</div>
					<div style={{ marginLeft: '20px', width: '85%' }}>
						<Skeleton height={22} width={100} />
						<Skeleton height={22} count={2} />
						<Skeleton height={22} width={340} />
					</div>
				</div>
				<div
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						display: 'flex',
					}}
				>
					<div style={{ width: '15%' }}>
						<Skeleton height={98} width={135} />
					</div>
					<div style={{ marginLeft: '20px', width: '85%' }}>
						<Skeleton height={22} width={100} />
						<Skeleton height={22} count={2} />
						<Skeleton height={22} width={340} />
					</div>
				</div>
				<div
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						display: 'flex',
					}}
				>
					<div style={{ width: '15%' }}>
						<Skeleton height={98} width={135} />
					</div>
					<div style={{ marginLeft: '20px', width: '85%' }}>
						<Skeleton height={22} width={100} />
						<Skeleton height={22} count={2} />
						<Skeleton height={22} width={340} />
					</div>
				</div>
			</div>
		)

		return (
			<Grid>
				<DocumentTitle title={getProgramTitle(t('SECTION_TIERS'))} />

				<Section title={t('SECTION_TIERS')} />

				{loading ? (
					<Fragment>
						<div className={styles.boxCategory}>
							<div
								style={{
									border: '1px solid #eee',
									padding: '20px ',
								}}
							>
								Categoria{' '}
								<span
									style={{
										color: '#21409a',
										fontWeight: 'bold',
									}}
								>
									{_.trim(currentTier.name)}
								</span>{' '}
								<br />
								{/* {_.isDate(validUntil) && `Valido até ${moment(validUntil).format("MM/YYYY")}`} */}
							</div>
							<div
								style={{
									border: '1px solid #eee',
									padding: '20px',
									width: 'auto',
								}}
							>
								<img
									src={currentTier.iconUrl}
									alt={currentTier.name}
									style={{ maxWidth: '100px' }}
								/>
							</div>
						</div>
						<br />
						{!currentTier.isFinalTier && (
							<p className={styles.Boxtext}>
								Para conquistar a categoria{' '}
								<span
									style={{
										color: '#21409a',
										fontWeight: 'bold',
									}}
								>
									{nextTier.name}
								</span>
								{', '}
								{/* {generalRulesData.hasExpireDate &&
                                ', no período de ' + generalRulesData.expireDate + ' ' + t(this.getExpirePointsTime(generalRulesData))
                            } */}
								acumule{' '}
								{nextTier.scoreStart
									? (
										nextTier.scoreStart -
										this.props.userData.points
									).toLocaleString()
									: ''}{' '}
								{generalRulesData.programCurrencyName}.
							</p>
						)}

						{tiers && (
							<Fragment>
								{tiers.map((item) => (
									<div
										style={{
											display: 'flex',
											padding: '20px 0',
										}}
										key={item.id}
									>
										<Grid
											item
											md={2}
											sm={2}
											xs={2}
											className={styles.imgBox}
										>
											<img
												src={item.iconUrl}
												alt={item.name}
												style={{ maxWidth: '100px' }}
											/>
										</Grid>
										<Grid
											item
											md={11}
											sm={11}
											xs={11}
											className={styles.textBox}
										>
											<span className={styles.title}>
												{item.name}
											</span>
											<br />
											{item.description}
										</Grid>
									</div>
								))}
							</Fragment>
						)}
					</Fragment>
				) : (
					<Fragment>{shimmer}</Fragment>
				)}
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		tierData: state.tier.tierData,
		tierDataFailed: state.tier.tierDataFailed,
		loading: state.loader.loading,
		activeTierData: state.tier.activeTierData,
		activeTierDataFailed: state.tier.activeTierDataFailed,
		userData: state.users.userData,
		generalRulesData: state.generalRules.generalRulesData,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ GetActiveTierData, GetCurrentConsumer, ShowLoader, HideLoader },
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation(),
)(Category)
