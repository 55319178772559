import React, { useEffect, useState } from 'react'
import { FormControlLabel, FormGroup } from '@material-ui/core'

import * as Styled from './styles'
import { Controller } from 'react-hook-form'
import { useMemo } from 'react'

const RegisterCheckbox = ({
    options,
    disabled,
    errors,
    control,
    setValue,
    watch,
}) => {
    const hasErrors = useMemo(
        () => options.find((option) => errors?.[option.name]),
        [errors, options],
    )

    let { useRegulation, useTerms, newsletter, selectAll } = watch()

    const [errorList, setErrorList] = useState([])

    useEffect(() => {
        if (useRegulation && useTerms && newsletter) {
            setValue('selectAll', true, { shouldValidate: true })
        }

        if (!useRegulation || !useTerms || !newsletter) {
            setValue('selectAll', false, { shouldValidate: true })
        }
    }, [useRegulation, useTerms, newsletter, setValue, selectAll])

    useEffect(() => {
        let errorList = []
        if (errors) {
            Object.keys(errors).forEach((error) => {
                if (options.find((option) => option.name === error)) {
                    errorList.push(error)
                }
            })
        }
        setErrorList(errorList)
    }, [errors, options])

    return (
        <Styled.CustomDiv>
            <Styled.CustomFormGroup
                component="fieldset"
                disableRipple
                error={hasErrors}
            >
                <FormGroup disableRipple>
                    {options?.map((option) => (
                        <Controller
                            key={option.name}
                            render={(
                                { onChange, onBlur, value, name, ref },
                                { invalid, isTouched, isDirty },
                            ) => {
                                return (
                                    <FormControlLabel
                                        key={option.name}
                                        disabled={disabled}
                                        label={option.label}
                                        disableRipple
                                        onBlur={onBlur}
                                        onChange={(e) => {
                                            onChange(e.target.checked)
                                            if (name === 'selectAll') {
                                                setValue(
                                                    'useTerms',
                                                    e.target.checked,
                                                    { shouldValidate: true },
                                                )
                                                setValue(
                                                    'useRegulation',
                                                    e.target.checked,
                                                    { shouldValidate: true },
                                                )
                                                setValue(
                                                    'newsletter',
                                                    e.target.checked,
                                                    { shouldValidate: true },
                                                )
                                            }
                                        }}
                                        checked={value}
                                        inputRef={ref}
                                        control={
                                            <Styled.CValeCheckbox
                                                disableRipple
                                                style={{
                                                    color:
                                                        errorList.find(
                                                            (error) =>
                                                                error ==
                                                                option.name,
                                                        ) && 'red',
                                                }}
                                            />
                                        }
                                    />
                                )
                            }}
                            name={option.name}
                            control={control}
                        />
                    ))}
                </FormGroup>
            </Styled.CustomFormGroup>
        </Styled.CustomDiv>
    )
}

export default RegisterCheckbox
