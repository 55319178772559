import React from 'react'
import _ from 'lodash'
import Axios from 'axios'
import {
    MASKS_IE,
    MASKSCNPJ,
} from '../components/PersonalData/helpers/errorsTreatment'
import { envs } from '../../../redux/api/windowServerData'

export const replaceCpfCnpj = (value) => value && value.replace(/[^\d]+/g, '')

export const EMAIL_DOES_NOT_VALIDATED = 'email - EMAIL_DOES_NOT_VALIDATED'

export const EMAIL_INVALID = 'Email - INVALID_VALUE'

export const PHONENUMBER_INVALID = 'PhoneNumber2 - INVALID_VALUE'

export const PHONENUMBER_DOES_NOT_VALIDATED =
    'phoneNumber - PHONENUMBER_DOES_NOT_VALIDATED'

export const CPF_NOT_ALLOWED = 'cpfCnpj - NOT_ALLOWED'

export const DUPLICATED = 'cpf - DUPLICATED'
export const DUPLICATED_CNPJ = 'cnpj - DUPLICATED'

export const defaultProps = {
    modalError: { open: false, content: '' }, //erro
    alert: false,
    modalProps: {
        open: false,
        text: 'CPF já cadastrado no programa.',
    },
    modalResendEmailProps: {
        open: false, //erro
        email: '',
    },
    modalResendSmsProps: {
        open: false,
    },
    modalAdmin: {
        open: false, //erro
    },
    modalMobileProps: {
        open: false,
    },
    modalDateOfBirth: {
        open: false,
        text: '',
    },
}

export const GeneralState = {
    corporationPartners: true,
    allowIndications: false,
}
export const defaultModal = {
    open: false,
    message: '',
}

export const validateChangesMaskValues = (unmaskedValues, formValues) => {
    return unmaskedValues === formValues ? unmaskedValues : formValues
}

export const ChangesMaskValues = (noMask, isMask) => {
    if (isMask.includes('*')) {
        return noMask
    }

    if (noMask === isMask) {
        return noMask
    }

    return isMask?.replace(/[^\d]+/g, '')
}

export const formatBody = (values, noMask, smsReceiveNumber, moment) => {
    return {
        IndicatorCode: values.IndicatorCode ? values.IndicatorCode : '',
        address: {
            addressType: values.cpf.length > 14 ? 2 : 1,
            aditionalInfo: !noMask.data
                ? values.address.aditionalInfo
                : noMask.data.address.aditionalInfo,
            cityId: values.address.cityId,
            countryId: values.address.countryId,
            district: !noMask.data
                ? values.address.district
                : noMask.data.address.district,
            location: !noMask.data
                ? values.address.location
                : noMask.data.address.location,
            number: !noMask.data
                ? values.address.number
                : noMask.data.address.number,
            stateId: values.address.stateId,
            zipCode: !noMask.data
                ? values.address.zipCode
                : noMask.data.address.zipCode,
        },
        confirmPassword: values.confirmPassword,
        cpf: values.cpf,
        stateRegistrationWaiver: values?.stateRegistrationWaiver,
        isMEI: values?.isMEI,
        stateRegistration: values.stateRegistrationWaiver
            ? null
            : !noMask.data?.stateRegistration
            ? values.stateRegistration?.replace(/[^\d]+/g, '')
            : ChangesMaskValues(
                  noMask.data.stateRegistration,
                  values.stateRegistration,
              ),
        dateOfBirth: !noMask?.data?.dateOfBirth
            ? moment(values.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : noMask?.data?.dateOfBirth,
        email: values.email?.includes('***@')
            ? !noMask.data
                ? values.email
                : noMask.data.email
            : values.email,
        gender: values.gender,
        name: !noMask.data ? values.name : noMask.data.name,
        newsletter: values.newsletter,
        password: values.password,
        phoneNumber: !noMask.data
            ? values.phoneNumber
            : validateChangesMaskValues(
                  noMask.data.phoneNumber,
                  values.phoneNumber,
              ),
        phoneNumber2: values.phoneNumber2?.includes('*')
            ? !noMask.data
                ? values.phoneNumber2
                : noMask.data.phoneNumber2
            : values.phoneNumber2,
        selectAll: values.selectAll,
        useRegulation: values.useRegulation,
        useTerms: values.useTerms,
        smsReceiveNumber: smsReceiveNumber,
        representative: {
            name: !noMask.data?.representative?.name
                ? values?.nameRP
                : ChangesMaskValues(
                      noMask?.data?.representative?.name,
                      values?.nameRP,
                  ),
            cpf: !noMask.data?.representative?.name
                ? values?.cpfRP
                : ChangesMaskValues(
                      noMask?.data?.representative?.cpf,
                      values?.cpfRP,
                  ),
            phoneNumber: values.phoneNumberRP?.includes('*')
                ? !noMask.data?.representative?.phoneNumber
                    ? values?.phoneNumberRP
                    : noMask?.data?.representative?.phoneNumber
                : values.phoneNumberRP,
            email: values.emailRP?.includes('***@')
                ? !noMask?.data?.representative?.email
                    ? values?.emailRP
                    : noMask?.data?.representative?.email
                : values.emailRP,
        },
    }
}

export const IsStateRegistrationValid = async (
    values,
    setModalRegistration,
    isPreRegistration,
) => {
    const { stateRegistrationWaiver, cpf, stateRegistration, listState } =
        values
    if (
        stateRegistrationWaiver === false &&
        cpf.replace(/[^\d]+/g, '').length > 11 &&
        stateRegistration
    ) {
        try {
            const stateId = stateRegistration
                ? stateRegistration?.replace(/[^a-zA-Z0-9]/g, '')
                : ''
            const response = await Axios.get(
                `/Consumer/IsStateRegistrationValid/${stateId}/${listState}`,
                {
                    // baseURL: envs.REACT_APP_GERMINI_API_URL,
                    baseURL: envs.REACT_APP_GERMINI_API_URL,
                },
            )
            const { data } = response
            if (data?.errors?.length) {
                const message = data?.errors[0]?.message

                if (isPreRegistration) {
                    return true
                }

                setModalRegistration({
                    open: true,
                    message:
                        data?.errors[0]?.messageCode === 1
                            ? 'StateRegistration'
                            : message,
                })
            }
            return data?.success
        } catch (e) {
            console.log(e)
        }
    }
    return true
}

export const RegistrationValid = async (values) => {
    const { stateRegistrationWaiver, cpf, stateRegistration, listState } =
        values
    if (
        stateRegistrationWaiver === false &&
        cpf?.replace(/[^\d]+/g, '')?.length > 11 &&
        stateRegistration &&
        listState
    ) {
        try {
            const stateId = stateRegistration
                ? stateRegistration?.replace(/[^a-zA-Z0-9]/g, '')
                : ''
            return await Axios.get(
                `/Consumer/IsStateRegistrationValid/${stateId}/${listState}`,
                {
                    // baseURL: envs.REACT_APP_GERMINI_API_URL,
                    baseURL: envs.REACT_APP_GERMINI_API_URL,
                },
            )
        } catch (e) {
            console.log(e)
        }
    }
    return true
}

export const onCloseModal = (setModal) => {
    setModal({
        open: false,
        message: '',
    })
}

export const handleStateRegistration = (ufs, form) => {
    const { stateRegistration, address } = form.getValues()

    const stateRegist = stateRegistration?.replace(/[^a-zA-Z0-9]/g, '') || ''

    form.setValue('stateRegistration', MASKS_IE(ufs, stateRegist))

    if (address?.stateId && stateRegistration)
        IsStateRegistrationValid(stateRegist, address?.stateId)
}

function validarEmail(values, noMask) {
    const isEmailValid = values.email?.includes('...@')

    if (isEmailValid) {
        if (!noMask.data) {
            return values.email
        } else {
            return noMask.data.email
        }
    } else {
        return values.email
    }
}

export const fillPreForm = (setValue, data) => {
    setValue('cpf', data?.cpf?.length > 11 ? MASKSCNPJ(data?.cpf) : data?.cpf)
    setValue('name', data?.name)
    setValue(
        'listState',
        Boolean(data?.stateRegistrationWaiver) ? '' : data?.address?.state?.id,
    )
    setValue('stateRegistration', data?.stateRegistration)
    setValue('isMEI', data?.isMEI)
    setValue('isPreRegistration', data?.isPreRegistration)
    setValue('disabledDateOfBirth', data?.disabledDateOfBirth)
    setValue('isStateDisabled', data?.isStateDisabled)
    setValue('phoneNumber2Pre', data?.phoneNumber2)
    setValue('phoneNumber2', data?.phoneNumber2)
    setValue('email', data?.email || '')
    setValue('emailPre', _.isEmpty(data?.email) ? undefined : data?.email)
    setValue('emailRpPre', data?.representative?.email || '')
    setValue('stateRegistrationWaiver', data?.stateRegistrationWaiver)
    setValue('phoneNumberRP', data?.representative?.phoneNumber)
    setValue('cpfRP', data?.representative?.cpf)
    setValue('nameRP', data?.representative?.name)
    setValue('emailRP', data?.representative?.email)
}
