import styled from 'styled-components'

export const CategoryItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
`

export const CategoryName = styled.div`
    flex: 1;
    width: 100%;
    text-align: center;
    max-height: 22px;
    color: ${({ themeColor }) => themeColor.titles.main.textColor};
`

export const CategoryImg = styled.img`
    width: 100%;
    max-width: 88px;
`
