import { format } from 'date-fns'
import { formatBRCurrency } from '../../../components/storybook'
import { numberFormatText } from 'utils/utils'

export function formatDate(date) {
    if (typeof date === 'string') {
        return format(new Date(date), 'dd/MM/yyyy') ?? '-'
    } else {
        return '-'
    }
}
export function formatDateModal(date) {
    return formatDate(date) ?? '-'
}

export function validateCurrencyMask(data) {
    const pointsMask = data?.simpleRules?.programCurrencySymbol

    function replaceDot(value) {
        if (typeof value === 'number') {
            return value.toString().replace('.', ',')
        }
        return value
    }

    if (
        data?.typeOfBenefit?.value === 'Cashback' &&
        data?.benefitConfigType?.value === 'Percentage'
    ) {
        return `${data?.value}%`
    }
    if (
        data?.typeOfBenefit?.value === 'Cashback' &&
        data?.benefitConfigType?.value === 'Manual'
    ) {
        return `${replaceDot(data?.value)}%`
    }

    if (data?.typeOfBenefit?.value === 'Cashback') {
        return formatBRCurrency(data?.value)
    }

    if (
        data?.typeOfBenefit?.value === 'Cashback' &&
        data?.benefitConfigType?.value === 'Fixed'
    ) {
        return formatBRCurrency(data?.value)
    }

    if (
        data?.typeOfBenefit?.value === 'Points' &&
        data?.benefitConfigType?.value === 'Percentage'
    ) {
        return `${data?.value}%`
    }

    if (
        data?.typeOfBenefit?.value === 'Points' &&
        data?.benefitConfigType?.value === 'Fixed'
    ) {
        return `${pointsMask} ${data?.value}`
    }
    if (
        data?.typeOfBenefit?.value === 'Points' &&
        data?.benefitConfigType?.value === 'Standard'
    ) {
        return `${pointsMask} ${data?.value}`
    } else {
        return '-'
    }
}
export function validateMaskTotal(data) {
    const pointsMask = data?.simpleRules?.programCurrencySymbol

    if (data?.typeOfBenefit?.value === 'Cashback') {
        return formatBRCurrency(data?.sellQuantity?.totalAllowed * data?.value)
    }
    if (data?.typeOfBenefit?.value === 'Points') {
        return `${pointsMask} ${data?.sellQuantity?.totalAllowed * data?.value}`
    }
}
export function validateMaskBalance(data) {
    const pointsMask = data?.simpleRules?.programCurrencySymbol

    if (data?.typeOfBenefit?.value === 'Cashback') {
        return formatBRCurrency(data?.sellQuantity?.remaining * data?.value)
    }
    if (data?.typeOfBenefit?.value === 'Points') {
        return `${pointsMask} ${data?.sellQuantity?.remaining * data?.value}`
    }
}
