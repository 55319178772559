import React from 'react'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SnackDefault from 'components/SnackDefault'
// @material-ui/core components
import {
    withStyles,
    createMuiTheme,
    MuiThemeProvider,
} from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
// import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
// icons
import Check from '@material-ui/icons/Check'
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx' // icons
// CSS
import styles from '../styles.module.scss'
import { login, logout, ClearSmsReducer } from '../../../../redux/actions'
import * as _ from 'lodash'
// Utils
import compose from 'utils/compose'
import axios from 'axios'
import {
    LoginContainer,
    FormContainer,
    WelcomeContainer,
    ButtonContainer,
} from '../styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { IconButton } from '@material-ui/core'
import { InputAdornment } from '@material-ui/core'
import { getErrors } from './LoginHelpers'
import {
    GetThemeColorFromStorage,
    SetThemeIntoStorage,
    GetThemeFromAPILogin,
    CVALE,
} from '../../../../redux/api/themeColor'
import LoginAlert from './LoginAlert'
import RecaptchaButton from './RecaptchaButton'
import MaskedInput from 'react-text-mask'

import requestOptions from './helpers/requestOptions'
import LoginInputButton from './LoginButton'
import { CustomButtonsBackground } from '../../../../components/CustomButtons'
import { Container, ContainerTitle } from './styles'

import Loader from 'components/Loader/Loader.jsx'

import Axios from 'axios'
import Modal from '../../../CreateUser/components/Modal'
import ModalDelete from '../ModalDelete'
import { envs } from '../../../../redux/api/windowServerData'
import { getProgramTitle } from '../../../../utils/utils'

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                color: 'rgba(0, 0, 0, 0.87)',
                padding: '6px 16px',
                fontSize: '0.875rem',
                minWidth: '64px',
                boxSizing: 'border-box',
                transition:
                    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                lineHeight: `1.75`,
                fontWeight: 500,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                borderRadius: '4px',
                textTransform: 'initial !important',
            },
        },
    },
    typography: {
        useNextVariants: true,
    },
})

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        // we use this to make the card to appear after the page has been rendered

        this.props.logout()

        this.state = {
            alert: null,
            cardAnimaton: 'cardHidden',
            login: '',
            loginState: '',
            password: '',
            passwordState: '',
            submitted: false,
            error: false,
            corporationPartners: true,
            generalRules: '',
            bannerPosition: 4,
            bannerInfo: [],
            isVerified: false,
            verifyState: true,
            showPassword: false,
            rememberMe: false,
            snackStatus: {
                open: false,
                severity: 'success',
                text: '',
            },
            isLogged: false,
            themeColor: {},
            programInfos: {},
            openModalDelete: false,
        }
    }
    //chamando a funcao asíncrona

    GetThemeFromAPI = () => {
        GetThemeFromAPILogin()
            .then((data) => {
                SetThemeIntoStorage(data)

                this.setState({
                    themeColor: data,
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    CPFMaskCustom = (props) => {
        const { inputRef, ...other } = props
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                guide={false}
                mask={(rawValue) =>
                    rawValue.replace(/[^\d]+/g, '').length <= 11 ||
                    !this.state.corporationPartners
                        ? [
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                          ]
                        : [
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                          ]
                }
                placeholderChar={'\u2000'}
            />
        )
    }

    btnCol = () => ({
        backgroundColor:
            this.state?.themeColor?.navigation?.buttons?.backgroundColor,
        elementsColor:
            this.state?.themeColor?.navigation?.buttons?.elementsColor,
        textColor: this.state?.themeColor?.navigation?.buttons?.textColor,
        logo: this.state?.themeColor?.images?.loginPage?.logo?.url,
    })

    handleTogglePassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }))
    }

    change(event, stateName) {
        const val = event.target.value
        const hasLenght = this.hasLenght(val)
        this.setState({
            [stateName + 'State']: hasLenght ? 'success' : 'error',
            [stateName]: hasLenght ? val : '',
        })
    }

    validateFields = () => {
        const len = (val) => this.hasLenght(val)
        if (!len(this.state.login)) {
            this.setState({ loginState: 'error' })
        }
        if (!len(this.state.password)) {
            this.setState({ passwordState: 'error' })
        }
        if (!this.state.isVerified) {
            this.setState({ verifyState: false })
        }
    }

    Set(key, value) {
        localStorage.setItem(key, value)
    }

    submit = (e) => {
        console.log('submit')
        e.preventDefault()

        this.setState({ error: false })
        this.setState({ submitted: true })

        this.setState({ verifyState: this.state.isVerified })
        const { login, password, rememberMe } = this.state

        this.Set('rememberMe', rememberMe)
        this.Set('login', rememberMe ? login : '')

        const _login = login.replace(/[^\d]+/g, '')

        if (
            (envs?.REACT_APP_RECAPTCHA_HIDDEN && _login && password) ||
            (_login && password && this.state.isVerified)
        ) {
            this.props.login(_login, password)
            this.GetThemeFromAPI()
        }
    }
    // function that verifies if a string has a given length or not

    verifyLength = (value, length) => value.length >= length
    hasLenght = (val) => this.verifyLength(val, 1)

    verifyNumber(value) {
        const numberRex = new RegExp('^[0-9]+$')
        return numberRex.test(value)
    }

    fidelidadeTitulo = () => localStorage.getItem('programName')

    componentDidMount() {
        const Remove = (_key) => {
            localStorage.removeItem(_key)
        }
        Remove('consumerSMS')
        Remove('cpfUser')

        if (this.props.location.snack) {
            this.setState({
                snackStatus: {
                    open: true,
                    severity: this.props.location.snack.severity,
                    text: this.props.location.snack.text,
                },
            })
        }

        if (envs.REACT_APP_CVALE_IDENTITY === 'true') {
            this.setState({ themeColor: CVALE })
        } else {
            this.GetThemeFromAPI()
        }

        if (this.props.location?.state?.error) {
            const { error } = this.props?.location?.state
            this.setState({
                alert: (
                    <Modal
                        open={true}
                        title="LINK EXPIRADO"
                        text={'Seu e-mail de validação do cadastro expirou.'}
                        onConfirm={() => this.handleSendEmail(error)}
                        type="tokenRegister"
                    />
                ),
            })
        }

        if (this.props.location?.state) {
            if (this.props.location?.state?.deleteAccount) {
                this.setState({ openModalDelete: true })
            }
        }

        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: '' })
            }.bind(this),
            700,
        )

        const remember = localStorage.getItem('rememberMe')
        const rememberMe = JSON.parse(remember) === true

        this.setState({ rememberMe: rememberMe })

        if (rememberMe === true) {
            const savedLogin = localStorage.getItem('login')
            this.setState({ login: savedLogin })
        }

        axios.get(`/ProgramRules/General/BasicData`).then((response) => {
            this.setState({
                corporationPartners: response.data.hasCorporationPartners,
                generalRules: response.data,
            })
        })

        axios
            .get(`/Banner/GetByPosition/${this.state.bannerPosition}`)
            .then((response) => {
                this.setState({
                    bannerInfo: response.data,
                })
            })

        this.getProgramInfos()
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction)
        this.timeOutFunction = null
    }

    sendEmailConfirmationToken = () => {
        return fetch(
            // `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${this.state.login}`,
            `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${this.state.login}`,
            requestOptions,
        ).then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text)
                return response.status === 200 ? data : Promise.reject(data)
            })
        })
    }
    getProgramInfos = () => {
        return axios
            .get('/ProgramRules/ProgramDescription/GetBasic')
            .then((response) => {
                this.setState({
                    programInfos: response.data,
                })
            })
    }

    parseHtmlContent(htmlString) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(htmlString, 'text/html')

        const img = doc.querySelector('img')
        const pElements = Array.from(doc.querySelectorAll('p'))

        return { img, pElements }
    }

    renderDescription() {
        const description = this?.state?.programInfos?.description
        if (!description) {
            return null
        }

        const { img, pElements } = this.parseHtmlContent(description)

        return (
            <div>
                {img && (
                    <img src={img?.src} alt={img?.alt} width={img?.width} />
                )}
                {pElements?.map((p, index) => (
                    <p
                        className="text-body"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: p.innerHTML }}
                        style={{ color: '#fff!important' }}
                    ></p>
                ))}
            </div>
        )
    }

    sendSMSActivationCode = () => {
        return fetch(
            // `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendSmsActivationCode?cpfCnpj=${this.state.login}`,
            `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendSmsActivationCode?cpfCnpj=${this.state.login}`,
            requestOptions,
        ).then((response) => {
            return response.text().then((text) => {
                const data = text && JSON.parse(text)
                if (response.status === 200) {
                    localStorage.setItem(
                        'consumerSMS',
                        JSON.stringify({
                            consumer: data.data,
                            expireDate: new Date().getTime() + 900000,
                        }),
                    )
                    return data
                }
                return Promise.reject(data)
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.bannerInfo !== this.state.bannerInfo) {
            this.handleMetricsCount(this.state.bannerInfo)
        }

        if (prevProps.logginFailed !== this.props.logginFailed) {
            if (this.props.logginFailed) {
                this.setState({ password: '' })
                // "Limite de acessos excedido. Aguarde uns minutos e tente novamente"
                const possibleErrors = getErrors(this)
                const currErr =
                    possibleErrors[this.props.logginFailed.error_description]
                this.setState(
                    currErr
                        ? {
                              alert: (
                                  <LoginAlert
                                      currErr={currErr}
                                      ___this={this}
                                  />
                              ),
                          }
                        : { error: true },
                )
            }
        }
    }

    handleMetricsCount = (item) => {
        const notEmp = (val) => !_.isEmpty(val)
        if (notEmp(this.state.bannerInfo) && notEmp(item.bannersPictures)) {
            axios.put(
                `/Banner/MetricsBannersCount/${
                    item.bannersPictures[0].id
                }?view=${true}`,
            )
        }
    }

    handleLink = (item) => {
        const link = item[1]
        if (link) {
            axios.put(`/Banner/MetricsBannersCount/${item[2]}?click=${true}`)
            const url = link
            const openLink =
                url.indexOf('http://') === 0 || url.indexOf('https://') === 0
                    ? link
                    : `https://${link}`
            window.open(openLink, '_blank')
        }
    }

    handleVerify = (e) => {
        if (e) this.setState({ isVerified: true })
    }

    handleChangeSwitch = () => {
        this.setState((prevState) => ({ rememberMe: !prevState.rememberMe }))
    }

    forgetPassword = () => {
        this.props.history.push('/resetpassword')
    }

    handleSendEmail = async (cpf) => {
        this.setState({ isLogged: true })
        const response = await Axios.put(
            `/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
            {
                // baseURL: envs.REACT_APP_GERMINI_API_URL,
                baseURL: envs.REACT_APP_GERMINI_API_URL,
            },
        )
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.setState({ isLogged: false })
            })

        if (response?.data) {
            const { errors, success } = response?.data

            if (errors?.length > 0) {
                this.onError(errors)
                return
            }

            if (success) this.onSuccess()
        }
    }

    onSuccess = () => {
        this.props.history.replace({
            pathname: this.props.location.pathname,
            state: {},
        })

        this.setState({
            alert: null,
            snackStatus: {
                open: true,
                severity: 'success',
                text: 'Email enviado com sucesso!',
            },
        })
    }

    onError = (errors) => {
        this.setState({
            alert: null,
            snackStatus: {
                open: true,
                severity: 'error',
                text: this.props.t(
                    errors[0].messageCode === 4
                        ? 'Seu e-mail já foi validado. Caso contrário, entre em contato com o suporte técnico'
                        : errors[0].message,
                ),
            },
        })
    }

    render() {
        const { t, classes, loggingIn, loggedIn, logginFailed } = this.props
        const { bannerInfo, isLogged } = this.state

        let usefulList = []
        if (!_.isEmpty(bannerInfo) && bannerInfo.status === 'ACTIVE') {
            usefulList = bannerInfo.bannersPictures.map((item) => [
                item.imageUrl,
                item.link,
                item.id,
            ])
        }

        console.log(
            'envs?.REACT_APP_RECAPTCHA_HIDDEN',
            Boolean(envs?.REACT_APP_RECAPTCHA_HIDDEN),
        )

        return (
            <Container borderColor={this.btnCol().elementsColor}>
                {isLogged && <Loader />}
                {this.state.alert}
                <SnackDefault
                    snackStatus={this.state.snackStatus}
                    handleCloseSnack={() =>
                        this.setState({
                            snackStatus: (prevState) => ({
                                ...prevState,
                                open: false,
                            }),
                        })
                    }
                />

                <ModalDelete
                    handleClose={() => {
                        this.setState({ openModalDelete: false })
                        this.props.history.push({
                            pathname: '/auth/login',
                            state: {},
                        })
                    }}
                    open={this.state.openModalDelete}
                />
                <div>
                    <DocumentTitle
                        title={getProgramTitle(t('PAGE_TITLE_LOGIN'))}
                    />
                    <GridContainer justify="center">
                        <LoginContainer container className={styles.root}>
                            <FormContainer item xs={12} sm={12} md={8}>
                                <form
                                    name="form"
                                    onSubmit={this.submit}
                                    className={styles.form}
                                >
                                    <Card
                                        login
                                        className={
                                            classes[this.state.cardAnimaton]
                                        }
                                        style={{ boxShadow: 'none' }}
                                    >
                                        <div style={{ textAlign: 'center' }}>
                                            <img
                                                src={this.btnCol().logo}
                                                alt="logo"
                                                width="200px"
                                                style={{
                                                    width: '200px',
                                                }}
                                            />
                                            <ContainerTitle
                                                className={styles.account}
                                                color={
                                                    this.btnCol().elementsColor
                                                }
                                            >
                                                Não tem uma conta?
                                                {loggedIn || loggingIn ? (
                                                    <b>Crie agora</b>
                                                ) : (
                                                    <b
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                '/register',
                                                            )
                                                        }
                                                    >
                                                        Crie agora
                                                    </b>
                                                )}
                                                , leva apenas alguns cliques.
                                            </ContainerTitle>
                                        </div>
                                        <CardBody
                                            className={styles.loginInputs}
                                        >
                                            <LoginInputButton
                                                type="login"
                                                labelText={
                                                    this.state
                                                        .corporationPartners
                                                        ? 'CPF/CNPJ'
                                                        : 'CPF'
                                                }
                                                state={this.state.loginState}
                                                inputProps={{
                                                    value: this.state.login,
                                                    disabled:
                                                        loggedIn || loggingIn,
                                                    inputComponent:
                                                        this.CPFMaskCustom,
                                                    onChange: (event) =>
                                                        this.change(
                                                            event,
                                                            'login',
                                                        ),
                                                }}
                                            />
                                            <LoginInputButton
                                                type="password"
                                                labelText={t('LOGIN_PASSWORD')}
                                                state={this.state.passwordState}
                                                inputProps={{
                                                    value: this.state.password,
                                                    disabled:
                                                        loggedIn || loggingIn,
                                                    onChange: (event) =>
                                                        this.change(
                                                            event,
                                                            'password',
                                                        ),
                                                    type: this.state
                                                        .showPassword
                                                        ? 'text'
                                                        : 'password',
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={
                                                                    this
                                                                        .handleTogglePassword
                                                                }
                                                            >
                                                                {this.state
                                                                    .showPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {this.state.error && (
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                        marginTop: '-15px',
                                                    }}
                                                >
                                                    <Typography
                                                        color="error"
                                                        style={{
                                                            fontWeight: '450',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {logginFailed.error_description
                                                            ? t(
                                                                  this.props
                                                                      .logginFailed
                                                                      .error_description,
                                                              )
                                                            : this.props
                                                                  .logginFailed}
                                                    </Typography>
                                                </div>
                                            )}
                                            <GridItem style={{ width: '100%' }}>
                                                <div
                                                    className={
                                                        styles.marginLeft
                                                    }
                                                >
                                                    {
                                                        <FormControlLabel
                                                            style={{
                                                                marginTop:
                                                                    '8px',
                                                            }}
                                                            checked={
                                                                JSON.parse(
                                                                    this.state
                                                                        .rememberMe,
                                                                ) === true
                                                            }
                                                            control={
                                                                <Checkbox
                                                                    disabled={
                                                                        loggedIn ||
                                                                        loggingIn
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .handleChangeSwitch
                                                                    }
                                                                    checkedIcon={
                                                                        <Check
                                                                            style={{
                                                                                color: GetThemeColorFromStorage()
                                                                                    ?.navigation
                                                                                    ?.buttons
                                                                                    ?.textColor,
                                                                                backgroundColor:
                                                                                    GetThemeColorFromStorage()
                                                                                        ?.navigation
                                                                                        ?.buttons
                                                                                        ?.elementsColor,
                                                                                borderColor:
                                                                                    GetThemeColorFromStorage()
                                                                                        ?.navigation
                                                                                        ?.buttons
                                                                                        ?.elementsColor,
                                                                            }}
                                                                            className={
                                                                                classes.checkedIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    icon={
                                                                        <Check
                                                                            className={
                                                                                classes.uncheckedIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        checked:
                                                                            classes.checked,
                                                                        root: classes.checkRoot,
                                                                    }}
                                                                    inputProps={{
                                                                        id: 'newVersion',
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label,
                                                            }}
                                                            label={t(
                                                                'LOGIN_REMEMBER_ME',
                                                            )}
                                                        />
                                                    }
                                                    {!(
                                                        loggedIn || loggingIn
                                                    ) ? (
                                                        <div
                                                            style={{
                                                                padding:
                                                                    '0 !important',
                                                            }}
                                                        >
                                                            <br />
                                                            <MuiThemeProvider
                                                                theme={theme}
                                                            >
                                                                <a
                                                                    href={() =>
                                                                        null
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .forgetPassword
                                                                    }
                                                                    style={{
                                                                        textDecoration:
                                                                            'none',
                                                                        cursor: 'pointer',
                                                                        color: this.btnCol()
                                                                            .elementsColor,
                                                                    }}
                                                                >
                                                                    {t(
                                                                        'LOGIN_FORGET_PASSWORD',
                                                                    )}
                                                                </a>
                                                            </MuiThemeProvider>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                padding:
                                                                    '0 !important',
                                                                marginRight:
                                                                    '-25px',
                                                            }}
                                                        >
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: this.btnCol()
                                                                        .elementsColor,
                                                                    textDecoration:
                                                                        'none',
                                                                    marginTop:
                                                                        '20px',
                                                                    transition:
                                                                        '0.2s',
                                                                }}
                                                            >
                                                                {t(
                                                                    'LOGIN_FORGET_PASSWORD',
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </GridItem>

                                            {!envs?.REACT_APP_RECAPTCHA_HIDDEN && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent:
                                                            'center',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <span>
                                                        Verificação de segurança
                                                    </span>
                                                    <ReCAPTCHA
                                                        sitekey={
                                                            envs.REACT_APP_RECAPTCHA_SITE_KEY
                                                        }
                                                        onChange={this.handleVerify.bind(
                                                            this,
                                                        )}
                                                    />
                                                    <RecaptchaButton
                                                        __this={this}
                                                    />
                                                </div>
                                            )}
                                        </CardBody>

                                        <GridItem
                                            container
                                            className={styles.loginButtons}
                                        >
                                            <ButtonContainer>
                                                <MuiThemeProvider theme={theme}>
                                                    <CustomButtonsBackground
                                                        // disabled={
                                                        //     (!envs?.REACT_APP_RECAPTCHA_HIDDEN &&
                                                        //         loggedIn) ||
                                                        //     (!envs?.REACT_APP_RECAPTCHA_HIDDEN &&
                                                        //         loggingIn)
                                                        // }
                                                        type="submit"
                                                    >
                                                        {t('BUTTON_SEND')}
                                                    </CustomButtonsBackground>
                                                    {loggingIn && (
                                                        <CircularProgress
                                                            color="github"
                                                            size={24}
                                                            className={
                                                                styles.buttonProgress
                                                            }
                                                            style={{
                                                                position:
                                                                    'fixeds',
                                                            }}
                                                        />
                                                    )}
                                                </MuiThemeProvider>
                                            </ButtonContainer>
                                        </GridItem>
                                    </Card>
                                </form>
                            </FormContainer>

                            <WelcomeContainer
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                themeColor={this.state.themeColor}
                            >
                                <div className="welcomeBox">
                                    <div className={styles.welcomeTitle}>
                                        BEM{' '}
                                        <span style={{ fontWeight: 700 }}>
                                            VINDO
                                        </span>{' '}
                                        AO
                                        <br />
                                        <span style={{ fontWeight: 700 }}>
                                            {this.state.generalRules?.name}
                                        </span>
                                    </div>
                                    <div
                                        className={styles.welcomeBody}
                                        style={{ color: '#fff' }}
                                    >
                                        {this.renderDescription()}
                                    </div>

                                    <br />
                                    {!_.isEmpty(usefulList) ? (
                                        <>
                                            {usefulList[0] && (
                                                <>
                                                    {usefulList.map(
                                                        (item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    onClick={() =>
                                                                        this.handleLink(
                                                                            item,
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            item[0]
                                                                        }
                                                                        alt="Banner Login"
                                                                        width="100%"
                                                                        style={{
                                                                            marginBottom:
                                                                                '30px',
                                                                            height: '150px',
                                                                            objectFit:
                                                                                'cover',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        },
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            width: '100%',
                                            height: '100%',
                                            zIndex: '5',
                                            color: '#fff',
                                            marginTop: '15px',
                                            fontSize: '0.8rem',
                                            opacity: '0.5',
                                        }}
                                    >
                                        <span>
                                            {'Release: ' +
                                                // `${envs.REACT_APP_GERMINI_BUILD_VERSION}`}
                                                `${envs.REACT_APP_GERMINI_BUILD_VERSION}`}
                                        </span>
                                    </div>
                                </div>
                            </WelcomeContainer>
                        </LoginContainer>
                    </GridContainer>
                </div>
            </Container>
        )
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    const { loggingIn, loggedIn, logginFailed } = state.authentication
    return {
        putSms: state.users.putSms,
        loggingIn,
        loggedIn,
        logginFailed,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ login, logout, ClearSmsReducer }, dispatch)

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle),
    withTranslation(),
)(LoginPage)
