/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// @Components
import AddressData from './components/AddressData'
import AccessData from './components/AccessData/index.js'
import Button from 'components/CustomButtons/Button.jsx'
import ContactData from './components/ContactData'
import Footer from 'components/Footer'
import Header from './components/Header'
import Loader from 'components/Loader/Loader.jsx'
import IndicationData from './components/IndicationData'
import PersonalData from './components/PersonalData'
import ResponsableData from './components/ResponsableData'
import SelectAllData from './components/SelectAllData'

// @Modals components
import ModalInform from './components/ModalInform'
import ModalAdmin from './components/ModalAdmin'
import ModalResendEmail from './components/ModalResendEmail'
import ModalMobile from './components/ModalMobile'
import ModalResendSms from './components/ModalResendSms'
import ModalMobileDuplicated from './components/ModalMobileDuplicated'

// @Assets
import registerStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { yupResolver } from '@hookform/resolvers'
import * as Style from './styles'
import Axios from 'axios'

// @Material-ui
import { Grid, TextField, withStyles } from '@material-ui/core'

// @Redux
import { GetGeneralRulesData } from '../../redux/api/generalRules.js'
import { GetPlatformRules } from '../../redux/api/platformRules'
import { GetRegulationData } from '../../redux/api/regulation'

// @Helpers
import { registerFormValidation } from './helpers/validation'
import { validationTreat, initialTreatment } from './helpers/validationTreat'
import { initialFormValues } from './helpers/initialValues'
import { PostUser } from '../../redux/api/user'
import moment from 'moment'

// @Helpers and Utils
import {
    defaultModal,
    defaultProps,
    formatBody,
    GeneralState,
    handleStateRegistration,
    IsStateRegistrationValid,
    onCloseModal,
    replaceCpfCnpj,
} from './helpers/utils'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import Modal from './components/Modal'
import ModalDateOfBirthError from './components/ModalDateOfBirthError'
import { envs } from '../../redux/api/windowServerData.js'
import { getProgramTitle } from '../../utils/utils.js'
import { GetContact } from '../../redux/api/footer.js'

function CreateUser({ classes }) {
    const { push } = useHistory()
    const { t } = useTranslation()

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')

    const [userCpf, setUserCpf] = useState({})
    const [isCnpj, setIsCnpj] = useState(false)
    const [userData, setUserData] = useState({})
    const [preDisabled, setPreDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [smsReceiveNumber, setSmsReceiveNumber] = useState(0)
    const [modalAlert, setModalAlert] = useState(defaultProps)
    const [modalRegistration, setModalRegistration] = useState(defaultModal)
    const [generalRules, setGeneralRules] = useState(GeneralState)
    const [ufs, setUfs] = useState()
    const [disableItems, setDisableItems] = useState({
        email: false,
        phone: false,
    })

    const [contact, setContat] = useState()

    const GetContactInfo = async () => {
        const res = await GetContact()
        res && setContat(res)
    }

    const form = useForm({
        defaultValues: initialFormValues,
        resolver: yupResolver(registerFormValidation),
        mode: 'all',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true,
    })

    const { cpf } = form.watch()

    useEffect(() => {
        ufs && handleStateRegistration(ufs, form)
    }, [ufs])

    const validateCellPhone = useCallback(async (value, errors) => {
        if (errors?.length) {
            if (errors[0].message === 'cellphonenumber - DUPLICATED')
                setModalAlert((prevState) => ({
                    ...prevState,
                    modalMobileProps: {
                        open: true,
                        phoneNumber: value.phoneNumber,
                        phoneNumber2: value.phoneNumber2,
                    },
                }))
        }
    }, [])

    const handleReset = () => {
        setPreDisabled(false)
        setUserCpf({})
        setUfs('')
        form.setValue('dateOfBirth', '')
        form.reset(initialFormValues)
    }

    const onSubmit = async (values) => {
        try {
            const stateRegistration = await IsStateRegistrationValid(
                form.watch(),
                setModalRegistration,
                form.watch('isPreRegistration'),
            )

            if (!stateRegistration) return

            const { data: noMask } = await Axios.get(
                `/Consumer/VerifyDocumentNoMask?cpfCnpj=${values.cpf}`,
                {
                    baseURL: envs.REACT_APP_GERMINI_API_URL,
                },
            )

            let body = formatBody(values, noMask, smsReceiveNumber, moment)

            const { data, errors, success } = await PostUser(body)

            validateCellPhone(values, errors)

            if (data) {
                localStorage.setItem(
                    'consumerSMS',
                    JSON.stringify({
                        consumer: data,
                        expireDate: new Date().getTime() + 900000,
                    }),
                )
            }

            validationTreat({
                errors,
                classes,
                t,
                success,
                data,
                state: modalAlert,
                setState: setModalAlert,
                push,
                preDisabled,
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const getInitialData = useCallback(async () => {
        setLoading(true)
        const generalRules = await GetGeneralRulesData()
        const platformRules = await GetPlatformRules()
        const regulationData = await GetRegulationData()
        setGeneralRules({
            ...generalRules,
            allowIndications: generalRules.allowIndications,
            corporationPartners: generalRules.hasCorporationPartners,
            useTerms: platformRules.useTerms,
            regulation: regulationData.regulation,
        })
        setLoading(false)
    }, [])

    useEffect(() => {
        getInitialData()
        GetContactInfo()
    }, [])

    const handleSendEmail = async () => {
        let cpf = replaceCpfCnpj(form.watch('cpf'))

        const { data } = await Axios.put(
            `/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
            {
                // baseURL: envs.REACT_APP_GERMINI_API_URL,
                baseURL: envs.REACT_APP_GERMINI_API_URL,
            },
        )

        initialTreatment({
            errors: data.errors,
            classes,
            t,
            success: data.success,
            data: data.data,
            state: modalAlert,
            setState: setModalAlert,
            push,
        })
    }

    const sendSMSActivationCode = async () => {
        let cpf = replaceCpfCnpj(form.watch('cpf'))

        const { data } = await Axios.put(
            `/Consumer/SendSmsActivationCode?cpfCnpj=${cpf}`,
            {
                // baseURL: envs.REACT_APP_GERMINI_API_URL,
                baseURL: envs.REACT_APP_GERMINI_API_URL,
            },
        )

        localStorage.setItem(
            'consumerSMS',
            JSON.stringify({
                consumer: data.data,
                expireDate: new Date().getTime() + 900000,
            }),
        )

        push(`/ConfirmRegistration`)
    }

    const handleLogin = () => {
        push(`/auth/login`)
    }

    const handleForgetPWD = () => {
        push(`/resetpassword`)
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
            {loading || (form.formState.isSubmitting && <Loader />)}

            {modalAlert.alert}
            <Style.Container container>
                <DocumentTitle title={getProgramTitle(t('TITLE_REGISTER'))} />
                <Header />

                <Style.GridBox
                    item
                    container
                    xs={12}
                    style={{
                        padding: '1rem',
                        marginTop:
                            // `${envs.REACT_APP_CVALE_IDENTITY}` === 'true'
                            `${envs.REACT_APP_CVALE_IDENTITY}` === 'true'
                                ? '4.1555rem'
                                : '2.5rem',
                    }}
                >
                    <Controller
                        as={<TextField style={{ display: 'none' }} />}
                        name="IsError"
                        control={form.control}
                        id="IsError"
                    />
                    <Controller
                        as={<TextField style={{ display: 'none' }} />}
                        name="InvalidEmail"
                        control={form.control}
                        id="InvalidEmail"
                    />
                    <PersonalData
                        {...{
                            ...form,
                            userCpf,
                            setUserCpf,
                            setModalAlert,
                            userData,
                            setUserData,
                            setPreDisabled,
                            preDisabled,
                            generalRules,
                            isCnpj,
                            setIsCnpj,
                            ufs,
                            setUfs,
                            token,
                            disableItems,
                            setDisableItems,
                        }}
                    />
                    <ContactData
                        {...{
                            ...form,
                            userCpf,
                            preDisabled,
                            userData,
                            disableItems,
                        }}
                    />
                    <AddressData
                        {...{
                            ...form,
                            userCpf,
                            userData,
                            preDisabled,
                            setUfs,
                            token,
                        }}
                    />

                    {replaceCpfCnpj(cpf)?.length > 11 ? (
                        <ResponsableData
                            {...{ ...form, userCpf, userData, preDisabled }}
                        />
                    ) : null}
                    <AccessData {...{ ...form, userCpf, userData }} />
                    {!!generalRules.allowIndications && (
                        <IndicationData {...{ ...form, userCpf }} />
                    )}
                    <SelectAllData {...{ ...form, userCpf, generalRules }} />

                    <Style.ButtonBox item xs={12} container>
                        <Grid item xs={12}>
                            <p>
                                {t(
                                    'FIELD_REGISTER_NOTIFICATION_ACCOUNT_ACTIVATION',
                                )}
                            </p>
                        </Grid>
                        <Grid item container xs={12} justify="center">
                            <Grid item xs={12} md={2}>
                                <Button
                                    size="sm"
                                    variant="contained"
                                    style={{
                                        backgroundColor: !_.isEmpty(form.errors)
                                            ? 'gray'
                                            : GetThemeColorFromStorage()
                                                  ?.navigation?.buttons
                                                  ?.backgroundColor,
                                    }}
                                    disabled={!_.isEmpty(form.errors)}
                                    type="submit"
                                >
                                    Finalizar cadastro
                                </Button>
                            </Grid>
                        </Grid>
                    </Style.ButtonBox>
                </Style.GridBox>
                <Footer />
                <ModalInform
                    open={modalAlert?.modalProps?.open}
                    text={modalAlert?.modalProps?.text}
                    handleLogin={() => handleLogin()}
                    handleForgetPWD={() => handleForgetPWD()}
                    handleClose={() => window.location.reload()}
                />

                <ModalAdmin
                    open={modalAlert?.modalAdmin?.open}
                    text={modalAlert?.modalAdmin?.text}
                    handleLogin={() => handleLogin()}
                    handleForgetPWD={() => handleForgetPWD()}
                    handleClose={() => window.location.reload()}
                />

                <ModalResendEmail
                    open={modalAlert?.modalResendEmailProps?.open}
                    email={modalAlert?.modalResendEmailProps?.email}
                    minWidth={1200}
                    handleSendEmail={() => handleSendEmail()}
                    handleClose={() => window.location.reload()}
                />

                <ModalResendSms
                    open={modalAlert?.modalResendSmsProps?.open}
                    minWidth={1200}
                    handleSendSms={() => sendSMSActivationCode()}
                    handleClose={() => window.location.reload()}
                />

                <ModalMobileDuplicated
                    open={modalAlert?.modalMobileProps?.open}
                    minWidth={'1200px'}
                    maxWidth={'1200px'}
                    handleClose={() =>
                        setModalAlert((prevState) => ({
                            ...prevState,
                            modalMobileProps: {
                                open: false,
                                phoneNumber: '',
                                phoneNumber2: '',
                            },
                        }))
                    }
                    changeModal={true}
                    smsReceiveNumber={smsReceiveNumber}
                    setSmsReceiveNumber={setSmsReceiveNumber}
                    phoneNumber={modalAlert?.modalMobileProps?.phoneNumber}
                    phoneNumber2={modalAlert?.modalMobileProps?.phoneNumber2}
                />

                <Modal
                    open={modalRegistration?.open}
                    text={modalRegistration?.message}
                    handleClose={() => onCloseModal(setModalRegistration, form)}
                    type="stateRegister"
                />
                <ModalDateOfBirthError
                    open={modalAlert?.modalDateOfBirth?.open}
                    text={modalAlert?.modalDateOfBirth?.text}
                    preDisabled={preDisabled}
                    handleResetDate={() => {
                        handleReset()
                        setModalAlert((prevState) => ({
                            ...prevState,
                            modalDateOfBirth: {
                                open: false,
                                text: '',
                            },
                        }))
                    }}
                />

                <ModalMobile
                    maxWidth="700px"
                    open={modalAlert?.modalError?.open}
                    closeByClick={() => {
                        handleReset()
                        setModalAlert((prevState) => ({
                            ...prevState,
                            modalError: {
                                open: false,
                                content: '',
                            },
                        }))
                    }}
                >
                    <Style.ErrorBox>
                        {modalAlert?.modalError?.content ? (
                            modalAlert?.modalError?.content
                        ) : (
                            <p>{`Identificamos que o número de celular informado está incorreto. É importante que seja corrigido para o recebimento do SMS de liberação do seu cadastro. Para atualização entre em contato com o suporte nos canais abaixo.`}</p>
                        )}
                        <p className="mt-2">
                            Contato:{' '}
                            <a
                                href={`mailto:${contact?.contactEmail}?Subject=Suporte`}
                                target="_top"
                            >
                                {contact?.contactEmail || ''}
                            </a>{' '}
                            | <span>+55 (44) 3689-7000</span>
                        </p>
                    </Style.ErrorBox>
                </ModalMobile>
            </Style.Container>
        </form>
    )
}

export default withStyles(registerStyle)(CreateUser)
