import React from 'react'
import { Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import * as S from './styles.js'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import GridItem from 'components/Grid/GridItem.jsx'
import * as moment from 'moment'
import { numberFormatText } from 'utils/utils'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor.js'
import { Link } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ececec',
        //borderRadius: '4px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        margin: '3px 0',
        '&:nth-child(even)': {
            backgroundColor: '#F4F4F4',
        },
    },
    arrowOpen: {
        backgroundColor: '#ececec',
        //borderRadius: '4px',
        borderBottomRightRadius: '4px',
        margin: '3px 0',
        '&:nth-child(even)': {
            backgroundColor: '#F4F4F4',
        },
    },
    arrowClose: {
        backgroundColor: '#ececec',
        //borderRadius: '4px',
        margin: '3px 0',
        '&:nth-child(even)': {
            backgroundColor: '#F4F4F4',
        },
    },
    button: {
        height: '100%',
        backgroundColor: germiniDefaultColors[0],
        textAlign: 'center',
        position: 'relative',
        top: '3px',
        display: 'flex',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        float: 'right',
        cursor: 'pointer',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '&:hover': {
            backgroundColor: germiniDefaultColors[9],
        },
    },
    buttonOpen: {
        height: '60px',
        backgroundColor: germiniDefaultColors[0],
        textAlign: 'center',
        display: 'flex',
        position: 'relative',
        top: '3px',
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
        float: 'right',
        cursor: 'pointer',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '&:hover': {
            backgroundColor: germiniDefaultColors[9],
        },
    },
}))

const _CREDIT = 1
const _DEBIT = 2

const tipeOfMov = [
    { name: 'Valor', symbol: '' },
    {
        name: localStorage.getItem('programCurrencyName'),
        symbol: localStorage.getItem('programCurrencySymbol'),
    },
    { name: 'CASHBACK', symbol: localStorage.getItem('currencySymbol') },
]

function GridTable({ data }) {
    const { t } = useTranslation()
    const classes = useStyles() //REMOVER E ADICIONAR NO STYLES
    const [expand, setExpand] = useState('none')

    useEffect(() => {
        setExpand('none')
    }, [data])

    function toggleDetail(index) {
        // if (expand === index) {
        //     return setExpand('')
        // }
        // setExpand(index)
        setExpand(expand === index ? '' : index)
    }
    const redirectToDetailPage = (id) => {
        return () => {
            window.location.href = `/negotiationDetails/${id}`
        }
    }

    return (
        <>
            {data &&
                data.map((item, index) => {
                    return (
                        <S.RowContainer valueColor={index}>
                            <S.TableGrid>
                                <S.ContainerAll>
                                    <S.ContainerPrimary>
                                        <S.ContentLabel>
                                            <div className="label">
                                                {t('Data de transação')}
                                            </div>
                                            <div className="textValue">
                                                {moment(
                                                    item?.transactionDate,
                                                ).format('L')}
                                            </div>
                                            <div className="subTextValue">
                                                {moment(
                                                    item?.transactionDate,
                                                ).format('HH:mm')}
                                            </div>
                                        </S.ContentLabel>
                                        <S.ContentLabel>
                                            <div className="label">
                                                {t('Id da transação')}
                                            </div>
                                            <div className="textValue">
                                                {item?.transactionCode
                                                    ? item.transactionCode
                                                    : ' - '}
                                            </div>
                                        </S.ContentLabel>
                                        <S.ContentLabel>
                                            <div className="label">
                                                {t('Processado em')}
                                            </div>
                                            <div className="textValue">
                                                {item?.processingDate
                                                    ? moment(
                                                          item?.processingDate,
                                                      ).format('L')
                                                    : ' - '}
                                            </div>
                                            <div className="subTextValue">
                                                {item?.processingDate
                                                    ? moment(
                                                          item?.processingDate,
                                                      ).format('HH:mm')
                                                    : ' - '}
                                            </div>
                                        </S.ContentLabel>
                                        <S.ContentLabel>
                                            <div className="label">
                                                {t('Liberado em')}
                                            </div>
                                            <div className="textValue">
                                                {item?.releaseDate
                                                    ? moment(
                                                          item?.releaseDate,
                                                      ).format('L')
                                                    : ' - '}
                                            </div>
                                            <div className="subTextValue">
                                                {item?.releaseDate
                                                    ? moment(
                                                          item?.releaseDate,
                                                      ).format('HH:mm')
                                                    : ' - '}
                                            </div>
                                        </S.ContentLabel>
                                    </S.ContainerPrimary>

                                    {expand === index && (
                                        <>
                                            {item && (
                                                <>
                                                    <Divider
                                                        style={{
                                                            width: '100%',
                                                            margin: '6px 0 10px 0',
                                                        }}
                                                    />
                                                    <S.ContainerPrimary>
                                                        <S.ContentLabel>
                                                            <div className="label">
                                                                {'Parceiro '}
                                                            </div>
                                                            <div className="textValue">
                                                                {
                                                                    item?.partnerName
                                                                }
                                                            </div>
                                                        </S.ContentLabel>
                                                        <S.ContentLabel>
                                                            <div className="label">
                                                                {t('Tipo')}
                                                            </div>
                                                            <div
                                                                className="textValue"
                                                                style={{
                                                                    color:
                                                                        item
                                                                            ?.entryType
                                                                            .key ===
                                                                        '2'
                                                                            ? 'red'
                                                                            : 'green',
                                                                }}
                                                            >
                                                                {t(
                                                                    item
                                                                        ?.entryType
                                                                        ?.value,
                                                                )}
                                                            </div>
                                                        </S.ContentLabel>
                                                        <S.ContentLabel>
                                                            <div className="label">
                                                                {t('Status')}
                                                            </div>
                                                            <div className="textValue">
                                                                {t(
                                                                    item?.status
                                                                        ?.value,
                                                                )}
                                                            </div>
                                                        </S.ContentLabel>
                                                        <S.ContentLabel>
                                                            <div className="label">
                                                                {t(
                                                                    'Valor gasto',
                                                                )}
                                                            </div>
                                                            <div className="textValue">
                                                                {item?.totalCurrency
                                                                    ? numberFormatText(
                                                                          item?.totalCurrency,
                                                                          localStorage.getItem(
                                                                              'currencySymbol',
                                                                          ) +
                                                                              ' ',
                                                                          2,
                                                                      )
                                                                    : ' - '}
                                                            </div>
                                                        </S.ContentLabel>
                                                    </S.ContainerPrimary>
                                                    <Divider
                                                        style={{
                                                            width: '100%',
                                                            margin: '6px 0 10px 0',
                                                        }}
                                                    />
                                                    <S.ContainerPrimary>
                                                        <S.ContentLabel>
                                                            <div className="label">
                                                                {item?.entryType
                                                                    .key === '2'
                                                                    ? 'Seed debitado'
                                                                    : 'Seed creditado'}
                                                            </div>
                                                            <div className="textValue">
                                                                {numberFormatText(
                                                                    item?.points
                                                                        ? item?.points
                                                                        : '0',
                                                                    localStorage.getItem(
                                                                        'programCurrencySymbol',
                                                                    ),
                                                                    0,
                                                                    true,
                                                                )}
                                                            </div>
                                                        </S.ContentLabel>
                                                        <S.ContentLabel>
                                                            <div className="label">
                                                                {t(
                                                                    'Data de expiração',
                                                                )}
                                                            </div>
                                                            <div className="textValue">
                                                                {item?.expirationDate
                                                                    ? moment(
                                                                          item?.expirationDate,
                                                                      ).format(
                                                                          'L',
                                                                      )
                                                                    : '-'}
                                                            </div>
                                                            <div className="textValue">
                                                                {item?.expirationDate &&
                                                                    moment(
                                                                        item?.expirationDate,
                                                                    ).format(
                                                                        'HH:mm',
                                                                    )}
                                                            </div>
                                                        </S.ContentLabel>
                                                        {!(
                                                            item?.origin
                                                                ?.key === '1' ||
                                                            item?.origin
                                                                ?.key === '2' ||
                                                            item?.origin
                                                                ?.key === '3' ||
                                                            item?.origin
                                                                ?.key === '4' ||
                                                            item?.origin
                                                                ?.key === '5' ||
                                                            item?.origin
                                                                ?.key === '9' ||
                                                            item
                                                                ?.transactionType
                                                                ?.key === '4'
                                                        ) && (
                                                            <S.ContentLabel>
                                                                <div className="label">
                                                                    {t(
                                                                        'Origem',
                                                                    )}
                                                                </div>
                                                                <div className="textValue">
                                                                    <Link
                                                                        onClick={redirectToDetailPage(
                                                                            item
                                                                                ?.origin
                                                                                ?.key,
                                                                        )}
                                                                        color="primary"
                                                                        underline="always"
                                                                        target="_blank"
                                                                        sx={{
                                                                            color: GetThemeColorFromStorage()
                                                                                ?.footer
                                                                                ?.backgroundColor,
                                                                            textDecorationColor:
                                                                                GetThemeColorFromStorage()
                                                                                    ?.footer
                                                                                    ?.backgroundColor,
                                                                            textDecorationStyle:
                                                                                'dashed',
                                                                            textDecorationSpacing:
                                                                                '0.5em',
                                                                            textUnderlinePosition:
                                                                                'under',
                                                                            cursor: 'pointer',

                                                                            ':hover':
                                                                                {
                                                                                    color: GetThemeColorFromStorage()
                                                                                        ?.footer
                                                                                        ?.backgroundColor,
                                                                                },
                                                                        }}
                                                                    >
                                                                        {
                                                                            item
                                                                                ?.origin
                                                                                ?.value
                                                                        }
                                                                    </Link>
                                                                </div>
                                                            </S.ContentLabel>
                                                        )}
                                                    </S.ContainerPrimary>
                                                </>
                                            )}
                                        </>
                                    )}
                                </S.ContainerAll>
                            </S.TableGrid>
                            <S.BtnContainer valueColor={index}>
                                {expand === index ? (
                                    <S.BtnArrow
                                        onClick={() => toggleDetail(index)}
                                    >
                                        <i
                                            style={{
                                                position: 'relative',
                                                color: GetThemeColorFromStorage()
                                                    ?.visual?.icons?.color,
                                            }}
                                            className={
                                                'icon-seta_acordium_aberto'
                                            }
                                        />
                                    </S.BtnArrow>
                                ) : (
                                    <S.BtnArrow
                                        onClick={() => toggleDetail(index)}
                                    >
                                        <i
                                            style={{
                                                fontSize: 28,
                                                color: GetThemeColorFromStorage()
                                                    ?.navigation?.menu
                                                    ?.highlightColor,
                                            }}
                                            className={
                                                'icon-seta_acordium_fechado'
                                            }
                                        />
                                    </S.BtnArrow>
                                )}
                            </S.BtnContainer>
                        </S.RowContainer>
                    )
                })}
        </>
    )
}

GridTable.propTypes = {
    labels: PropTypes.string,
    values: PropTypes.string,
    history: PropTypes.string,
    id: PropTypes.string,
    data: PropTypes.array,
}

GridTable.defaultProps = {
    labels: '',
    values: '',
    history: '',
    id: '',
    data: [],
}

export default withTranslation()(GridTable)
