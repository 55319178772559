import React from 'react'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import {
    GridContainer,
    GridItem,
    MuiBox,
    MuiCheckbox,
} from '../../../../components/storybook'
import {
    formatDateModal,
    validateCurrencyMask,
    validateMaskBalance,
    validateMaskTotal,
} from '../../../Negotiation/functions'
import { ModalButton } from '../../../ShopHistory/styles'
import { CssTextField, config, typeGroup } from '../../utils'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useTranslation } from 'react-i18next'

export function Details({ negotiationDetails, handleOpenModal }) {
    const { t } = useTranslation()

    const category =
        negotiationDetails &&
        negotiationDetails.typeOfGrouper?.value !== 'Product' &&
        negotiationDetails.benefitConfigType?.value === 'Percentage'

    const product =
        negotiationDetails &&
        negotiationDetails.typeOfGrouper?.value === 'Product' &&
        negotiationDetails.benefitConfigType?.value === 'Fixed'

    const productPercentage =
        negotiationDetails &&
        negotiationDetails.typeOfGrouper?.value === 'Product' &&
        negotiationDetails.benefitConfigType?.value === 'Percentage'

    const manual =
        negotiationDetails &&
        negotiationDetails.typeOfGrouper?.value === 'Product' &&
        negotiationDetails.benefitConfigType?.value === 'Manual'

    const manualGroupers =
        negotiationDetails &&
        negotiationDetails.typeOfGrouper?.value !== 'Product' &&
        negotiationDetails.benefitConfigType?.value === 'Manual'

    const groupers =
        negotiationDetails &&
        negotiationDetails.typeOfGrouper?.value !== 'Product' &&
        negotiationDetails.benefitConfigType?.value === 'Fixed'

    return (
        <>
            <GridItem xs={12} sm={12} md={9} lg={9} mb={3}>
                <CssTextField
                    id="data"
                    name="data"
                    label="Origem da negociação"
                    value={
                        negotiationDetails?.negotiationCampaign?.value ?? '-'
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3}>
                <></>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3}>
                <CssTextField
                    id="data"
                    name="data"
                    label="Data de criação"
                    value={
                        formatDateModal(negotiationDetails?.createdAt) ?? '-'
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3}>
                <CssTextField
                    id="data"
                    name="data"
                    label="Data de início"
                    value={
                        formatDateModal(negotiationDetails?.validFrom) ?? '-'
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3}>
                <CssTextField
                    id="data"
                    name="data"
                    label="Data de expiração"
                    value={
                        formatDateModal(negotiationDetails?.validUntil) ?? '-'
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            {negotiationDetails?.sellQuantity?.partialConsumption && (
                <GridItem xs={12} sm={12} md={3} lg={3}>
                    <CssTextField
                        id="data"
                        name="data"
                        label="Transferência à partir de:"
                        value={
                            formatDateModal(
                                negotiationDetails?.transferValidAfter,
                            ) ?? '-'
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        disabled={true}
                    />
                </GridItem>
            )}
            <GridItem
                xs={12}
                sm={12}
                md={3}
                lg={3}
                mt={
                    negotiationDetails?.sellQuantity?.partialConsumption ? 3 : 0
                }
            >
                <CssTextField
                    id="data"
                    name="data"
                    label="Número de contrato"
                    value={negotiationDetails?.contractNumber ?? '-'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            <GridContainer
                justify="left"
                style={{ paddingTop: '10px', paddingLeft: '7px' }}
            >
                <ModalButton>
                    <span onClick={handleOpenModal}>
                        <i
                            style={{
                                margin: '0px 8px',
                                fontSize: 25,
                                position: 'relative',
                                top: '8px',
                                color: GetThemeColorFromStorage()?.navigation
                                    ?.buttons?.backgroundColor,
                            }}
                            className={'icon-detalhe_pagamento'}
                        />
                        Unidades de faturamento
                    </span>
                </ModalButton>
            </GridContainer>
            {category && (
                <>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label={
                                typeGroup[
                                    negotiationDetails?.typeOfGrouper?.value
                                ]
                            }
                            value={negotiationDetails?.grouper?.value ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de Notas Fiscais"
                            value={
                                negotiationDetails?.sellQuantityInvoice
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de itens"
                            value={
                                negotiationDetails?.sellQuantity
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {product && (
                <>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label={
                                typeGroup[
                                    negotiationDetails?.typeOfGrouper?.value
                                ]
                            }
                            value={negotiationDetails?.product?.value ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Código SKU"
                            value={negotiationDetails?.product?.key ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de itens"
                            value={
                                negotiationDetails?.sellQuantity
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {productPercentage && (
                <>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label={
                                typeGroup[
                                    negotiationDetails?.typeOfGrouper?.value
                                ]
                            }
                            value={negotiationDetails?.product?.value ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Código SKU"
                            value={negotiationDetails?.product?.key ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de itens"
                            value={
                                negotiationDetails?.sellQuantity
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {manual && (
                <>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label={
                                typeGroup[
                                    negotiationDetails?.typeOfGrouper?.value
                                ]
                            }
                            value={negotiationDetails?.product?.value ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Código SKU"
                            value={negotiationDetails?.product?.key ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={2} lg={2} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de itens"
                            value={
                                negotiationDetails?.sellQuantity
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {manualGroupers && (
                <>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label={
                                typeGroup[
                                    negotiationDetails?.typeOfGrouper?.value
                                ]
                            }
                            value={negotiationDetails?.grouper?.value ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Código SKU"
                            value={negotiationDetails?.grouper?.key ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={2} lg={2} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de itens"
                            value={
                                negotiationDetails?.sellQuantity
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {groupers && (
                <>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label={
                                typeGroup[
                                    negotiationDetails?.typeOfGrouper?.value
                                ]
                            }
                            value={negotiationDetails?.grouper?.value ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6} lg={6}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Código SKU"
                            value={negotiationDetails?.grouper?.key ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={2} lg={2} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Qtde de itens"
                            value={
                                negotiationDetails?.sellQuantity
                                    ?.totalAllowed ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            <GridItem
                xs={12}
                sm={12}
                md={category ? 12 : 4}
                lg={category ? 12 : 4}
                mt={category ? 3 : 5}
            >
                <MuiBox
                    sx={{
                        '& .MuiFormGroup-root': {
                            paddingLeft: '11px !important',
                        },
                    }}
                >
                    <MuiCheckbox
                        labeltxt="liberação de saldo parcial"
                        onChange={function noRefCheck() {}}
                        selected={
                            negotiationDetails?.allowPartialAvailabilityOfValue
                        }
                        disabled
                    />
                </MuiBox>
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                <CssTextField
                    id="data"
                    name="data"
                    label="Benefício"
                    value={t(negotiationDetails?.typeOfBenefit?.value) ?? '-'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            {category && (
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <></>
                </GridItem>
            )}
            {product && (
                <>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Configuração do benefício"
                            value={
                                config[
                                    negotiationDetails?.benefitConfigType?.value
                                ] ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Valor (por item)"
                            value={
                                negotiationDetails
                                    ? validateCurrencyMask(negotiationDetails)
                                    : '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Total (AGUARDANDO RETORNO)"
                            value={validateMaskTotal(negotiationDetails) ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Saldo (AGUARDANDO RETORNO)"
                            value={
                                validateMaskBalance(negotiationDetails) ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                            className={'blue-border'}
                        />
                    </GridItem>
                </>
            )}
            {manual && (
                <>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Configuração do benefício"
                            value={
                                config[
                                    negotiationDetails?.benefitConfigType?.value
                                ] ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Valor (por item)"
                            value={
                                negotiationDetails
                                    ? validateCurrencyMask(negotiationDetails)
                                    : '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {manualGroupers && (
                <>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Configuração do benefício"
                            value={
                                config[
                                    negotiationDetails?.benefitConfigType?.value
                                ] ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Valor (por item)"
                            value={
                                negotiationDetails
                                    ? validateCurrencyMask(negotiationDetails)
                                    : '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                </>
            )}
            {groupers && (
                <>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Configuração do benefício"
                            value={
                                config[
                                    negotiationDetails?.benefitConfigType?.value
                                ] ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Valor (por item)"
                            value={
                                negotiationDetails
                                    ? validateCurrencyMask(negotiationDetails)
                                    : '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Total (AGUARDANDO RETORNO)"
                            value={validateMaskTotal(negotiationDetails) ?? '-'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                        <CssTextField
                            id="data"
                            name="data"
                            label="Saldo (AGUARDANDO RETORNO)"
                            value={
                                validateMaskBalance(negotiationDetails) ?? '-'
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            disabled={true}
                            className={'blue-border'}
                        />
                    </GridItem>
                </>
            )}
            {product && (
                <GridItem xs={12} sm={12} md={3} lg={3} mt={3}>
                    <></>
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={6} lg={6} mt={3}>
                <CssTextField
                    id="data"
                    name="data"
                    label="Vendedor"
                    value={negotiationDetails?.sellersName || '-'}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={true}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} mt={3}>
                <label
                    htmlFor="descricao"
                    style={{
                        marginLeft: '15px',
                        fontSize: '12px',
                        lineHeight: 1.2,
                        minWidth: 300,
                        color: grayColor[2],
                        fontWeight: 300,
                    }}
                >
                    Descrição
                </label>
                <MuiBox
                    sx={{
                        width: '100%',
                        height: '100px',
                        '&::-moz-placeholder': {
                            color: '#717171',
                        },
                        '&::-webkit-input-placeholder': {
                            color: '#717171',
                        },
                        '&::placeholder': {
                            color: '#717171',
                        },
                        '& textarea': {
                            border: 'none',
                            padding: '20px',
                            borderRadius: '5px',
                            width: '100%',
                            maxWidth: '100%',
                            minWidth: '100%',
                            maxHeight: '100%',
                            minHeight: '100%',
                            fontFamily: 'Roboto, Helvetica, Arial ,sans-serif',
                            fontSize: '15px',
                            backgroundColor: '#F4F4F4',
                            color: '#838383',
                        },
                    }}
                >
                    <textarea
                        id="descricao"
                        placeholder={'Descrição'}
                        label="Descrição"
                        value={negotiationDetails?.description || '-'}
                        maxLength={350}
                        disabled
                    />
                </MuiBox>
            </GridItem>
        </>
    )
}
