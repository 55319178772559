import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled(Grid)`
    position: relative;
    max-height: 116px;

    p {
        margin: 0;
    }

    > div {
        padding: 0.5rem 2rem;
    }

    > img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .btn-login {
        @media screen and (min-width: 768px) {
            transform: translateY(-19px) !important;
        }
    }

    .header {
        position: relative;

        > div {
            display: flex;
            align-items: center;
        }

        img {
            width: 7rem;
            height: 100%;
        }

        button {
            span {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .footer {
        background-image: linear-gradient(#9f9f9f, #cbc9c9);
        color: #fff;
        text-align: center;
    }

    @media screen and (min-width: 768px) {
        max-height: 170px;

        .footer,
        p,
        span,
        b {
            font-size: 24px;
        }

        .header {
            img {
                width: 200px;
            }

            > img {
                width: 100%;
            }
        }

        .header-button {
            justify-content: flex-end;
            margin-bottom: -202px;

            button {
                margin-right: 42px;

                height: 66px;
                border-radius: 8px;

                p {
                    font-size: 14px;
                }

                b {
                    font-size: 20px;
                }
            }
        }

        .footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 24px;

            margin-top: -12px;
            border-radius: 8px;
            height: 90px;

            > p:first-child {
                margin-bottom: 0.5rem;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 0 26px;

        .MuiGrid-grid-xs-12 {
            max-width: 90%;
            margin: 0 auto;
        }

        .header-button {
            margin-bottom: -224px;

            button {
                margin-right: 20px;
            }
        }
    }

    @media screen and (min-width: 1460px) {
        .MuiGrid-grid-xs-12 {
            max-width: 75%;
        }
    }
`
