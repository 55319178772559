/* eslint-disable */
import React, { memo, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'

import * as Style from './styles'
import { useHistory } from 'react-router-dom'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { transform } from 'lodash'
import { useTranslation } from 'react-i18next'

function Header() {
    const { t } = useTranslation()
    const { push } = useHistory()

    const [theme, setTheme] = useState({})

    useEffect(() => {
        setTheme(GetThemeColorFromStorage)
    }, [])

    const images = theme?.images?.internalPages

    return (
        <Style.Container item container xs={12}>
            <img
                src={images?.menuBackground?.url}
                alt={`${images?.menuBackground?.filename}`}
            />
            <Grid item xs={12} className="header" container>
                <Grid item xs={6}>
                    <img
                        src={images?.menuLogo?.url}
                        alt={`${images?.menuLogo?.filename}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => push('/')}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    container
                    justify="flex-end"
                    className="header-button"
                >
                    <Button
                        size="sm"
                        variant="contained"
                        color="primary"
                        className="btn-login"
                        disableRipple
                        onClick={() => push('/auth/login')}
                        style={{
                            backgroundColor:
                                theme?.navigation?.buttons?.backgroundColor,
                            color: theme?.navigation?.buttons?.textColor,
                            borderColor: `transparent`,
                            //transform: 'translateY(-19px)',
                        }}
                    >
                        <p>{t('I_M_ALREADY_REGISTERED')}</p>
                        <b>{t('ACCESS')}</b>
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} className="footer">
                <p>
                    <b>{t('REGISTER')}</b> {t('AND_HAVE_MANY')}{' '}
                    <b>{t('BENEFITS')} </b>
                </p>
                <p>
                    {t('AT_THE')} <b>{t('WORLD')}</b> {t('OF_AGRIBUSINESS')}
                </p>
            </Grid>
        </Style.Container>
    )
}

export default memo(Header)
