import _ from 'lodash'
import Cookies from 'js-cookie'
import { createBrowserHistory } from 'history'
import { envs } from '../redux/api/windowServerData'

export const history = createBrowserHistory()
export { authHeader } from './auth-header'
export { configureFakeBackend } from './fake-backend'

export const handleAuthCheck = () => {
    try {
        if (
            history.location.pathname !== '/auth/login' &&
            history.location.pathname !== '/confirmToken' &&
            history.location.pathname !== '/resetPassword'
        ) {
            if (`${envs.REACT_APP_CVALE_IDENTITY}` !== 'true') {
                if (_.isEmpty(JSON.parse(Cookies.get('themeColor')))) {
                    handleAuthLogout()
                }
            }
        }
    } catch (error) {
        console.log(error)
        handleAuthLogout()
    }
}

const handleAuthLogout = () => {
    localStorage.clear()
    Cookies.remove('themeColor')
    history.push('/auth/login')
}
